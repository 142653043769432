.card-submenu-title {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 15px;
}

.card-submenu-title-h2 {
  margin-left: 15px;
  font-weight: 500;
  line-height: 1.2;
}

.approval-content {
    height: 100%;
    width: 75%;
    padding: 10px;
    background-color: #eeeee2;
    background-image: radial-gradient(circle at center, white 3px, transparent 4px), radial-gradient(circle at center, white 3px, transparent 4px);
    background-size: 14px 12px;
    background-position: center -6px, center calc(100% + 6px);
    background-repeat: repeat-x;
  }
  
  .approval-code-txt {
    letter-spacing: 3px;
    font-size: 18pt;
    font-weight: 800;
    text-align: center;
    font-style: italic;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .approval-code {
    letter-spacing: 1em;
  }

  .mask-password {
    -webkit-text-security: disc;     
  }
  // Here you can add other styles
