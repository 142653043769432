// Import Main styles for this application
@import "../scss/style.scss";

// Spinkit
@import "~spinkit/spinkit.min.css";
.sk-chase {
  --sk-color: #321FDC;
}
//ladda
@import "~ladda/dist/ladda-themeless.min.css";
