.steps {
  ul {
    display: flex;
    display: -webkit-flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    justify-content: space-around;
    -moz-justify-content: space-around;
    -webkit-justify-content: space-around;
    -o-justify-content: space-around;
    -ms-justify-content: space-around;
    li {
      padding-right: 80px;
      padding-left: 0;
      position: relative;
      color: #999;
      text-decoration: none;
      font-weight: 700;
      &:last-child {
        &:after {
          width: 0;
        }
      }
    }
    .current {
      color: #321fdc;
      .number {
        border: 2px solid #321fdc;
      }
    }
    .done {
      color: #1abc9c;
      .number {
        position: relative;
        font-size: 0;
        border: 2px solid #1abc9c;
        &:after {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          font-size: 14px;
          color: #1abc9c;
          content: "\2713";
          font-weight: 700;
        }
      }
    }
  }
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  padding: 12px 20px;
}
.title {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
}
.number {
  display: flex;
  display: -webkit-flex;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
  justify-content: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  border: 2px solid #999;
  margin-right: 15px;
}
.content {
  padding-top: 20px;
}

@media screen and (max-width: 44em) {
  .steps {
    ul {
      padding: 0 0.5em;
    }

    ul > li {
      width: 100%;
      padding: 0.45em 0.25em;
      margin: 0 0 0.55em 0;

      // &:after {
      //   box-shadow: none;
      // }
    }
  }
}
