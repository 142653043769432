@import "form-wizard.scss";

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-address {
  height: auto;
  min-height: calc(1.5em + 0.75rem + 2px);
}
